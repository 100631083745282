<template>
  <div class="mine-page">
    <div class="user-form-info">
      <div class="user-form-item is-photo">
        <p class="label">头像</p>
        <img class="user-phone" src="../../assets/images/index/home_21.jpg" alt="">
      </div>
      <div class="user-form-item">
        <p class="label">昵称</p>
        <p>谁谁谁 <van-icon name="arrow" /></p>        
      </div>
    </div>
    
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
export default {
  data(){
    return {
      
    }
  },
  created() {
    
  },
  mounted() {
    
  },
  methods:{
  }
}
</script>

<style lang='less' scoped>
  .mine-page{
    margin:0 16px;
  }
  .user-form-item{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:28px 0;
    border-bottom:1px solid rgba(0,0,0,0.1);
    &.is-photo{
      padding:16px 0;
    }
    .user-phone{
      width:80px;
      height:80px;
      border-radius:40px;
    }
  }
  
</style>
